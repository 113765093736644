import React, { useEffect, useRef, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { BsInfoCircle } from 'react-icons/bs';
import InfoPopover from './InfoPopover';
import Wave from '../assets/images/wave.png';
import MarkdownRenderer from 'react-markdown-renderer';

const Chats = ({ insightsDetails, drawer, setDrawer, insightData }) => {
  const [inputValue, setInputValue] = useState('');
  const [messageData, setMessageData] = useState([]);
  const messageDataRef = useRef(messageData);

  const socketRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [currentGuid, setCurrentGuid] = useState(null);

  useEffect(() => {
    messageDataRef.current = messageData;
  }, [messageData]);

  const handleSubmitChat = (value) => {
    if (!insightData?.guid) { return; }
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          command: 'input',
          text: value,
        })
      );
      setInputValue('');
    }
  };

  useEffect(() => {
    if (insightData?.guid && insightData.guid !== currentGuid) {
      setCurrentGuid(insightData.guid);

      if (socketRef.current) {
        socketRef.current.close();
      }

      const newSocket = new WebSocket(
        `wss://demo.assetwatch.info/insights/ws/v1/conversations/${insightData.guid}`
      );

      newSocket.onopen = () => {
        setMessageData([]);
        console.log('Socket connected');
        socketRef.current = newSocket;
      };

      newSocket.onmessage = ({ data }) => {
        const parsedData = JSON.parse(data);

        if (parsedData.text && !parsedData.append) {
          setMessageData((prevMessageData) => [...prevMessageData, parsedData]);
        }

        if (parsedData.text && parsedData.append) {
          const isMessageExist = messageDataRef.current.find(
            (msg) => msg.guid.toString() === parsedData.guid.toString()
          );

          setMessageData((prevMessageData) =>
            isMessageExist
              ? prevMessageData.map((msg) =>
                  msg.guid === parsedData.guid
                    ? { ...msg, text: msg.text + parsedData.text }
                    : msg
                )
              : [...prevMessageData, parsedData]
          );
        }
      };

      newSocket.onclose = () => {
        setMessageData([]);
        console.log('Socket closed');
      };

      socketRef.current = newSocket;
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [insightData?.guid]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messageData]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitChat(inputValue);
    }
  };

  return (
    <div className='rounded-l-lg p-5 h-full w-full flex flex-col'>
      <div className='flex border-b pb-5'>
        {/* <button
          onClick={() =>
            setDrawer({ ...drawer, chats: false, chatsAnimation: true })
          }
        >
          <IoIosArrowForward fontSize={24} color='#939393' />
        </button> */}
        <h5 className='font-bold text-xl text-darkBlue flex gap-2 items-center'>
          Dive Deeper
          <InfoPopover message="Favorite the questions your're asking to include it in future analytics calculations that should determine how the analytics are generated.">
            <BsInfoCircle />
          </InfoPopover>
        </h5>
      </div>
      <div
        ref={chatContainerRef}
        className='flex-1 overflow-auto scrollbar-none my-5 max-h-full space-y-5'
      >
        {!insightData?.guid ? (
          <div className='text-gray-400 text-center'> Loading Chats... </div>
        ) : (
          messageData.map((chat) => {
            const conversation = insightsDetails?.chats?.conversation;
            const userProfile =
              conversation?.userProfile ||
              'https://pagedone.io/asset/uploads/1705471668.png';
            const agentProfile = conversation?.agentProfile || Wave;
            return (
              <div key={chat?.guid}>
                {(chat?.sender === 'user') ? (
                    <div className='flex flex-row-reverse gap-3 items-center'>
                      <div>
                        <img
                          width={50}
                          src={userProfile}
                          className='!h-[50px] !max-w-[50px] !w-[50px]'
                          alt='profile'
                        />
                      </div>
                      <div className='description inline-block rounded-xl bg-[#4A4D540A] p-3'>
                        {chat?.text}
                      </div>
                    </div>
                ) : (
                  <div className='flex gap-3'>
                    <div className='bg-orange rounded-full h-fit w-fit'>
                      <img
                        width={50}
                        src={agentProfile || '/assets/images/wave.png'}
                        className='!h-[50px] !max-w-[50px] p-3 !w-[50px]'
                        alt='profile'
                      />
                    </div>
                      <div><MarkdownRenderer markdown={chat?.text} /></div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
      <div className='relative mt-5'>
        <input
          type='text'
          className='w-full px-5 py-3 outline-none rounded-lg border bg-white'
          placeholder='Ask anything...'
          value={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
          onKeyPress={handleKeyPress}
          disabled={!insightData?.guid}
        />
        <span onClick={() => handleSubmitChat(inputValue)}>
          <IoIosSend className='text-[26px] text-maroon absolute right-5 top-3' />
        </span>
      </div>
    </div>
  );
};

export default Chats;
