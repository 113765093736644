import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
} from 'react-icons/tb';

import { ENDPOINTS } from '../api/endpoints';
import { reportsTabs } from '../helpers/constants';
import { formatDate } from '../helpers/commonFunctions';

import Chats from '../components/Chats';
import Insights from '../components/Insights';
import MapFilters from '../components/MapFilters';
import TabsAndSearch from '../components/TabsAndSearch';

const defaultFilters = {
  dpd: null,
  state: null,
  model: null,
  search: null,
  during: null,
  insight: null,
  selectedCar: null,
  mapState: 'State',
  activeClusterTab: reportsTabs?.[0],
};

const viewOptions = ['State', 'Metros'];

const Reports = () => {
  const [drawer, setDrawer] = useState({
    insightsAnimation: false,
    chatsAnimation: false,
    insights: false,
    chats: false,
  });
  const [insights, setInsights] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectDate, setSelectDate] = useState([new Date(), new Date()]);
  const [insightData, setInsightData] = useState(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const [startdate, enddate] = selectDate;
    if (startdate && enddate) {
      const fetchInsightLists = async () => {
        try {
          setLoading(true)
          const response = await axios.post(ENDPOINTS?.REPORT_INSIGHTS, {
            startdate: formatDate(startdate),
            enddate: formatDate(enddate),
            mapType: filters?.mapState,
          });

          setInsights(response?.data);
          setFilters({
            ...filters,
            insight: response?.data?.[0],
          });
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log('Error fetching insights:', error);
        }
      };

      fetchInsightLists();
    }
  }, [filters?.activeClusterTab, filters?.mapState]);

  useEffect(() => {
    const fetchInsightData = async () => {
      try {
        const [startdate, enddate] = selectDate;

        const payload = {
          startdate: formatDate(startdate),
          enddate: formatDate(enddate),
          insight_id: filters?.insight?.id,
          model: 2000,
          mapType: filters?.mapState,
        };

        if (filters?.model) payload.model_filter = filters?.model;

        if (filters?.dpd) payload.dpd_filter = filters?.dpd;

        if (filters?.dpd) payload.dpd_filter = filters?.dpd;
        setLoading(true)
        const response = await axios.post(ENDPOINTS?.REPORT_DATA, payload);
        const data = response?.data;

        setInsightData(data);

        const updateFilters = {};

        if (!filters?.model)
          updateFilters.model = data?.modelsFilter?.[0]?.value;
        if (!filters?.dpd) updateFilters.dpd = data?.DPDFilter?.[0]?.value;
        if (!filters?.during)
          updateFilters.during = data?.duringFilter?.[0]?.value;

        if (Object.keys(updateFilters).length > 0)
          setFilters({ ...filters, ...updateFilters });
        setLoading(false)
      } catch (error) {
        console.log('Error fetching insights data:', error);
        setLoading(false)
      }
    };

    if (filters?.insight?.id) {
      fetchInsightData();
    }
  }, [
    filters?.insight?.id,
    filters?.mapState,
    filters?.model,
    filters?.dpd,
    filters?.during,
    selectDate,
  ]);

  return (
    <div
      className={`rounded-xl flex flex-col ${
        window?.innerWidth <= 1301 ? 'h-full' : 'h-[calc(100vh-200px)]'
      }`}
    >
      <TabsAndSearch
        filters={filters}
        hiddenExport={true}
        hiddenTabIcons={true}
        setFilters={setFilters}
        clusterTabs={reportsTabs}
        classes={'[&]:justify-start'}
        rootClasses={'w-11/12 mx-auto'}
        loading={loading}
      />
      <div
        className={`flex-1 flex md:flex-col gap-3 overflow-hidden pt-3 ${
          drawer?.chats || drawer?.insights ? 'mb-0' : 'mb-8'
        }`}
      >
        {drawer?.insights || drawer?.insightsAnimation ? (
          <div
            className={`xl:fixed left-0 bottom-0 z-[2] xl:shadow-lg bg-white rounded-[20px] border-r xl:border-r-0 xl:border-b w-full max-w-[400px] scrollbar-none ${
              drawer?.insights ? 'slider-in' : 'slider-out'
            }`}
            style={{
              height:
                window?.innerWidth >= 1301
                  ? '100%'
                  : `calc(100% - ${
                      window?.innerWidth >= 769 ? '197px' : '273px'
                    })`,
            }}
            onAnimationEnd={() => {
              if (!drawer?.insights) {
                setDrawer({ ...drawer, insightsAnimation: false });
              }
            }}
          >
            <Insights
              drawer={drawer}
              filters={filters}
              insights={insights}
              setDrawer={setDrawer}
              setFilters={setFilters}
              selectDate={selectDate}
              setSelectDate={setSelectDate}
            />
          </div>
        ) : (
          <div className='flex justify-between'>
            <button
              onClick={() =>
                setDrawer({ ...drawer, insights: !drawer?.insights })
              }
              className='h-[52px] w-[56px] bg-white rounded-r-full shadow-lg'
            >
              <TbLayoutSidebarRightCollapse
                size={28}
                color='#939393'
                className='h-full m-auto'
              />
            </button>

              {/* <button
              onClick={() => setDrawer({ ...drawer, chats: !drawer?.chats })}
              className='h-[52px] hidden md:block w-[56px] bg-white rounded-l-full shadow-lg'
            >
              <TbLayoutSidebarRightExpand
                size={28}
                color='#939393'
                className='h-full m-auto'
              />
            </button> */}
          </div>
        )}
        <div className='flex-1 flex flex-col gap-3 overflow-hidden md:p-4'>
          <div
            className={`tab-content flex-1 font-poppinsRegular bg-white rounded-[20px] ${
              (drawer?.chats || drawer?.insights) && window?.innerWidth <= 1300
                ? 'overflow-hidden'
                : 'overflow-auto'
            }`}
          >
            <div className='grid grid-cols-1'>
              <div className='col-span-3 p-3 overflow-auto flex flex-col'>
                {!drawer?.insights && (
                  <div
                    className={`relative rounded-[20px] ${
                      filters?.mapState !== 'Map View'
                        ? 'min-h-fit'
                        : 'min-h-[500px]'
                    }`}
                  >
                    <MapFilters
                      drawer={drawer}
                      filters={filters}
                      setDrawer={setDrawer}
                      mapViews={viewOptions}
                      setFilters={setFilters}
                      insightsDetails={insightData}
                      viewClasses={`[&]:border border-gray-300`}
                      classes={`[&&]:relative [&]:[@media(min-width:640px)_and_(max-width:927px)]:justify-center ${
                        drawer?.insights &&
                        drawer?.chats &&
                        '[&]:[@media(min-width:1301px)_and_(max-width:1667px)]:justify-center'
                      }`}
                    />

                    <div className='p-5 pb-0'>
                      <h1 className='text-2xl mb-5'>
                        {filters?.insight?.title}
                      </h1>

                      {insightData?.chartData?.length && (
                        <div className='mt-4 max-w-[525px]'>
                          <ResponsiveContainer width='100%' height={400}>
                            <BarChart
                              data={insightData?.chartData}
                              margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <CartesianGrid strokeDasharray='3 3' />
                              <XAxis dataKey='name' />
                              <YAxis
                                label={{
                                  value: '# of VINs',
                                  angle: -90,
                                  position: 'insideLeft',
                                }}
                              />
                              <Tooltip />
                              <Bar dataKey='count' fill='#4A5FFF' />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className='p-5'>
                  {insightData?.summary &&
                    insightData?.summary?.map((data, index) => (
                      <p className='mt-5 font-poppinsRegular' key={index}>
                        {data}
                      </p>
                    ))}

                  {insightData?.common && (
                    <div className='mt-4'>
                      Common :
                      {insightData?.common?.map((data) => (
                        <div className='ms-10'>{data}</div>
                      ))}
                    </div>
                  )}
                </div>
                <Chats
                  drawer={drawer}
                  setDrawer={setDrawer}
                  insightData={filters?.insight}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {drawer?.chats || drawer?.chatsAnimation ? (
          <div
            className={`max-w-[400px] xl:fixed xl:shadow-lg bg-white rounded-[20px] right-0 bottom-0 z-[2] overflow-hidden ${
              drawer?.chats ? 'slider-in-chat' : 'slider-out-chat'
            }`}
            style={{
              height:
                window?.innerWidth >= 1301
                  ? '100%'
                  : `calc(100% - ${
                      window?.innerWidth >= 769 ? '197px' : '273px'
                    })`,
            }}
            onAnimationEnd={() => {
              if (!drawer?.chats) {
                setDrawer({ ...drawer, chatsAnimation: false });
              }
            }}
          >
            <Chats
              drawer={drawer}
              setDrawer={setDrawer}
              insightData={filters?.insight}
            />
          </div>
        ) : (
          <button
            onClick={() => setDrawer({ ...drawer, chats: true })}
            className='h-[52px] md:hidden block w-[56px] bg-white rounded-l-full shadow-lg'
          >
            <TbLayoutSidebarRightExpand
              size={28}
              color='#939393'
              className='h-full m-auto'
            />
          </button>
        )} */}
      </div>
    </div>
  );
};

export default Reports;
