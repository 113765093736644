import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Adjust based on your routing library

const SignUp = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const handlerOnChange = (e) => {
    const { value, name } = e?.target;
    setData({ ...data, [name]: value });
  };

  const handlerSubmit = () => {
    if (data?.password !== data?.confirmPassword) {
      setIsPasswordMatch(false);
    } else {
      navigate('/sign-in');
    }
  };

  return (
    <div className='flex items-center justify-center p-0 sm:p-4 min-h-screen h-full bg-peach font-poppinsRegular'>
      <div className='w-full max-w-md p-8 bg-white rounded-lg shadow-lg'>
        <div className='flex bg-peach justify-center gap-3 items-center rounded-lg p-4'>
          <div className='flex items-center justify-center'>
            <img
              src='/assets/images/main-logo.png'
              alt='Company Logo'
              className='w-24 h-24 object-cover'
            />
          </div>
          <h1 className='text-2xl font-bold text-orange text-center'>
            Asset Watch
          </h1>
        </div>
        <h2 className='text-xl font-semibold text-orange my-2 text-center'>
          Sign Up
        </h2>
        <form onSubmit={handlerSubmit}>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='first-name'
            >
              First Name
            </label>
            <input
              required
              type='text'
              id='first-name'
              name='firstName'
              placeholder='First Name'
              onChange={handlerOnChange}
              className='w-full px-3 py-2 border border-gray-300 focus-visible:outline-orange rounded-md'
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='last-name'
            >
              Last Name
            </label>
            <input
              required
              type='text'
              id='last-name'
              name='lastName'
              placeholder='Last Name'
              onChange={handlerOnChange}
              className='w-full px-3 py-2 border border-gray-300 focus-visible:outline-orange rounded-md'
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='email'
            >
              Email
            </label>
            <input
              required
              type='email'
              id='email'
              name='email'
              placeholder='Your Email'
              onChange={handlerOnChange}
              className='w-full px-3 py-2 border border-gray-300 focus-visible:outline-orange rounded-md'
            />
          </div>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='password'
            >
              Password
            </label>
            <input
              required
              type='password'
              id='password'
              name='password'
              onChange={handlerOnChange}
              placeholder='Your Password'
              className='w-full px-3 py-2 border border-gray-300 focus-visible:outline-orange rounded-md'
            />
          </div>
          <div className='mb-6'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='confirm-password'
            >
              Confirm Password
            </label>
            <input
              required
              type='password'
              id='confirm-password'
              name='confirmPassword'
              onChange={handlerOnChange}
              placeholder='Confirm Password'
              className='w-full px-3 py-2 border border-gray-300 focus-visible:outline-orange rounded-md'
            />
            {!isPasswordMatch && (
              <span className='text-red-500 text-sm'>
                Password don't match with confirm password.
              </span>
            )}
          </div>
          <button
            type='submit'
            className='w-full py-2 px-4 bg-orange text-white rounded-md hover:bg-darkOrange'
          >
            Sign Up
          </button>
        </form>
        <div className='mt-4 text-center'>
          <p className='text-gray-700'>Already have an account?</p>
          <Link to='/sign-in' className='text-orange font-semibold'>
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
