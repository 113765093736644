import clsx from 'clsx';

import SelectHeadless from './SelectHeadless';

const MapFilters = ({
  filters,
  classes,
  mapViews,
  setFilters,
  viewClasses,
  insightsDetails,
  viewRootClasses,
}) => {
  return (
    <div
      className={clsx(
        `w-full p-5 xxl:p-2 xxl:pt-5 flex justify-between flex-wrap gap-2 sm:flex-col absolute z-[1]`,
        classes,
      )}
    >
      <div className='flex gap-5 flex-wrap xxl:gap-2 sm:justify-between'>
        {insightsDetails?.DPDFilter?.length && (
          <SelectHeadless
            options={insightsDetails?.DPDFilter}
            value={filters?.dpd}
            onChange={(newDpd) => setFilters({ ...filters, dpd: newDpd?.value })}
            label='Days past due'
          />
        )}
        {insightsDetails?.modelsFilter?.length && (
          <SelectHeadless
            clasess='min-w-[180px]'
            options={insightsDetails?.modelsFilter}
            value={filters?.model}
            onChange={(newModel) => setFilters({ ...filters, model: newModel?.value })}
            label='Model'
          />
        )}
        {insightsDetails?.duringFilter?.length && (
          <SelectHeadless
            options={insightsDetails?.duringFilter}
            value={filters?.during}
            onChange={(newModel) => setFilters({ ...filters, during: newModel?.value })}
            label='During'
          />
        )}
        {insightsDetails?.statesFilter?.length && filters?.mapState === 'List View' && (
          <SelectHeadless
            options={insightsDetails?.statesFilter}
            value={filters?.model}
            onChange={(newModel) => setFilters({ ...filters, state: newModel?.value })}
            label='State'
            placeholder='Choose an option'
          />
        )}
      </div>
      <div className={clsx('flex gap-5 justify-center flex-wrap sm:w-full', viewRootClasses)}>
        {mapViews?.length && (
          <div
            className={clsx(
              'flex h-11 rounded-lg bg-white w-full border p-1 shadow-lg',
              viewClasses,
            )}
          >
            {mapViews?.map((item) => (
              <button
                key={item}
                className={clsx(
                  'py-[6px] min-w-[100px] w-full tracking-wide font-poppinsRegular rounded-lg',
                  filters?.mapState === item ? 'text-orange bg-[#FFDCD9]' : 'text-[#00000080]',
                )}
                onClick={() => setFilters({ ...filters, mapState: item })}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MapFilters;
