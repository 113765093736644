import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

export default function PopoverHeadless({ buttonToggle, open, children }) {
  return (
    <Popover data-open={open} className='w-full'>
      <PopoverButton className='block focus:outline-none w-full'>{buttonToggle}</PopoverButton>
      <PopoverPanel
        transition
        anchor='bottom end'
        className='divide-y divide-darkBlue/25 rounded-lg mt-1 bg-white border border-darkBlue/25 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 z-[999]'
      >
        {children}
      </PopoverPanel>
    </Popover>
  );
}
