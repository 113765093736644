import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from './pages/Layout';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import FinDash from './pages/FinDash';
import Reports from './pages/Reports';
import RepoPanel from './pages/Repopanel';

const App = () => {
  return (
    <Routes>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />

      <Route path='/' element={<Layout />}>
        {/* <Route path='/' element={<Navigate to='/sign-in' />} /> */}
        <Route path='/' element={<Navigate to='/repo-panel' />} />
        <Route path='/repo-panel' element={<RepoPanel />} />
        <Route path='/fin-dash' element={<FinDash />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/*' element={<Navigate to='/repo-panel' />} />
        {/* <Route path='/*' element={<Navigate to='/sign-in' />} /> */}
      </Route>
    </Routes>
  );
};

export default App;
