import React from 'react';
import clsx from 'clsx';
import { FaChevronDown } from 'react-icons/fa';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';

export default function SelectHeadless({
  options,
  value,
  onChange,
  label,
  placeholder,
  clasess,
  disabled,
}) {
  const selectedOption = options?.find((option) => option?.value === value);

  const handleOptionClick = (item) => {
    onChange(item?.value);
  };

  return (
    <Listbox value={value} onChange={onChange}>
      <ListboxButton
        className={clsx(
          'relative block rounded-lg py-1.5 pr-10 pl-3 text-left text-sm/6 border border-gray-300 bg-white',
          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
          'font-poppins',
          clasess,
        )}
      >
        <span className='flex gap-1 truncate text-clip'>
          {label}:
          {selectedOption ? (
            <span
              className='block truncate text-orange whitespace-nowrap overflow-ellipsis'
              title={selectedOption?.label}
            >
              {selectedOption?.label}
            </span>
          ) : (
            <span
              className='block truncate whitespace-nowrap overflow-ellipsis'
              title={placeholder || 'Select an option'}
            >
              {placeholder || 'Select an option'}
            </span>
          )}
        </span>

        <FaChevronDown
          className='group pointer-events-none absolute top-1/3 right-2.5 size-4'
          aria-hidden='true'
        />
      </ListboxButton>

      <ListboxOptions
        anchor='bottom'
        transition
        className={clsx(
          'w-[var(--button-width)] rounded-b-xl divide-y border border-gray-200 bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-[999]',
          'font-poppins',
        )}
      >
        {options?.length > 0 &&
          options?.map((option) => (
            <ListboxOption
              key={option?.value}
              value={option}
              onClick={() => handleOptionClick(option)}
              disabled={option?.disabled}
              className={'cursor-pointer'}
            >
              {({ selected }) => (
                <span
                  className={`block truncate w-auto rounded-md py-2 px-3 hover:text-orange ${
                    selected ? 'bg-gray-200 text-gray-900' : 'text-gray-600'
                  } ${option?.value === value ? 'text-orange' : 'font-light'}`}
                >
                  {option?.label}
                </span>
              )}
            </ListboxOption>
          ))}
      </ListboxOptions>
    </Listbox>
  );
}
