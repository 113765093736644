import Popup from 'reactjs-popup';

function InfoPopover({ message, children }) {
  return (
    <Popup
      trigger={<button className='button'>{children}</button>}
      position={['top center', 'top left']}
      on={['hover']}
      contentStyle={{
        backgroundColor: 'rgb(243 243 243)',
        borderRadius: '15px',
        padding: '5px 10px',
        color: 'black',
        minWidth: '300px',
      }}
      arrowStyle={{
        color: 'rgb(243 243 243)',
      }}
    >
      {message}
    </Popup>
  );
}

export default InfoPopover;
