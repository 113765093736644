import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import MarkdownRenderer from 'react-markdown-renderer';

import { TbLocationFilled } from 'react-icons/tb';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { GoDotFill } from 'react-icons/go';

import { ENDPOINTS } from '../api/endpoints';
import { formatDate } from '../helpers/commonFunctions';
import { ClusteredCars } from '../components/ClusteredCars';
import {
  clusterTabs,
  daysOfParking,
  defaultMapBounds,
  odometerFilter,
} from '../helpers/constants';

import Reports from './Reports';
import Chats from '../components/Chats';
import Insights from '../components/Insights';
import MapFilters from '../components/MapFilters';
import TabsAndSearch from '../components/TabsAndSearch';

const defaultFilters = {
  dpd: null,
  model: null,
  insight: null,
  mapState: 'States',
  selectedCar: null,
  odometer: odometerFilter?.[0]?.id,
  parkingDays: daysOfParking?.[0]?.id,
  activeClusterTab: clusterTabs?.[0],
};

const mapViewOptions = ['Metros', 'States'];

const RepoPanel = () => {
  const socketRef = useRef(null);

  const [drawer, setDrawer] = useState({
    chats: false,
    insights: false,
    chatsAnimation: false,
    insightsAnimation: false,
  });
  const [data, setMapData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [insightsData, setInsightsData] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [mapBottomFilters, setMapBottomFilters] = useState(null);
  const [hoverClusterDetails, setHoverClusterDetails] = useState(null);
  const [selectDate, setSelectDate] = useState([
    new Date().setDate(new Date().getDate() - 7),
    new Date(),
  ]);

  const handleZoomChange = ({ detail }) => {
    setZoomLevel(detail?.zoom);
    if (detail?.zoom <= 5) {
      setStateData(null);
      setHoverClusterDetails(null);
    }
  };

  useEffect(() => {
    const [startdate, enddate] = selectDate;

    const createSocket = () => {
      const params = {
        panel_type: 'repopanel',
        mapType: filters?.mapState,
        enddate: formatDate(enddate),
        startdate: formatDate(startdate),
        insight_id: filters?.insight?.id,
        page_name: filters?.activeClusterTab?.key,
      };
      const queryParams = new URLSearchParams(params).toString();
      const newSocket = new WebSocket(
        `wss://demo.assetwatch.info/ws/updates?${queryParams}`
      );

      newSocket.onopen = () => {
        console.log('Socket connected');
        socketRef.current = newSocket;

        const message = {
          dpd_filter: filters?.dpd,
          model_filter: filters?.model,
          map_type: filters?.mapState,
        };
        if (filters?.activeClusterTab?.id === 1)
          message.parkingDays = filters?.parkingDays;

        if (filters?.activeClusterTab?.id === 2)
          message.odometer = filters?.odometer;

        newSocket.send(JSON.stringify(message));
      };

      newSocket.onmessage = ({ data }) => {
        setMapData(JSON.parse(data));
      };

      return () => newSocket.close();
    };

    if (enddate && startdate && filters?.insight && filters?.mapState) {
      createSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [selectDate, filters?.insight, filters?.mapState]);

  useEffect(() => {
    if (socketRef.current) {
      const message = {
        model_filter: filters?.model,
        dpd_filter: filters?.dpd,
      };

      if (filters?.activeClusterTab?.id === 1)
        message.parkingDays = filters?.parkingDays;

      if (filters?.activeClusterTab?.id === 2)
        message.odometer = filters?.odometer;

      socketRef.current.send(JSON.stringify(message));
    }
  }, [filters?.model, filters?.dpd, filters?.parkingDays, filters?.odometer]);

  useEffect(() => {
    if (stateData) {
      const findStateData = data?.find(
        (state) => state?.key === stateData?.key
      );
      setStateData(findStateData);
    }
  }, [data]);

  useEffect(() => {
    let apiRoutes = null;

    switch (filters?.activeClusterTab?.id) {
      case 0:
        setMapBottomFilters(null);
        apiRoutes = ENDPOINTS?.CLUSTERS_DATA;
        break;
      case 1:
        setMapBottomFilters(daysOfParking);
        apiRoutes = ENDPOINTS?.NIGHTLY_PARKING_DATA;
        break;
      case 2:
        setMapBottomFilters(odometerFilter);
        apiRoutes = ENDPOINTS?.ODOMETER_DATA;
        break;
      default:
        setMapBottomFilters(null);
        break;
    }

    const [startdate, enddate] = selectDate;

    if (
      enddate &&
      apiRoutes &&
      startdate &&
      filters?.activeClusterTab?.key !== 'reports'
    ) {
      (async () => {
        try {
          const payload = {
            startdate: formatDate(startdate),
            enddate: formatDate(enddate),
          };

          if (filters?.dpd) payload.dpd_filter = filters?.dpd;

          if (filters?.model) payload.model = filters?.model;

          if (filters?.insight) payload.insight_id = filters?.insight?.id;

          setLoading(true);
          const response = await axios.post(apiRoutes, payload);
          setMapData([]);
          const data = response?.data;
          setInsightsData(data);
          setFilters({
            ...filters,
            mapState: data?.mapType,
            insight: data?.Insights?.[0],
            dpd: data?.DPDFilter?.[0]?.value,
            model: data?.modelsFilter?.[0]?.value,
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log('Error fetching insights:', error);
        }
      })();
    }
  }, [selectDate, filters?.activeClusterTab, selectDate]);

  return (
    <div
      className={`rounded-xl border h-full flex flex-col ${
        (drawer?.chats || drawer?.insights) && window?.innerWidth <= 1301
          ? '!h-[calc(100vh-100px)]'
          : 'h-full'
      }`}
    >
      <TabsAndSearch
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        clusterTabs={clusterTabs}
        classes='[&]:[@media(min-width:0px)_and_(max-width:940px)]:justify-start'
      />

      {filters?.activeClusterTab?.key === 'reports' ? (
        <Reports />
      ) : (
        <div
          className={`flex-1 flex md:flex-col gap-3 overflow-hidden pt-3 ${
            drawer?.chats || drawer?.insights ? 'mb-0' : 'mb-8'
          }`}
        >
          {drawer?.insights || drawer?.insightsAnimation ? (
            <div
              className={`xl:fixed xl:ml-0 ml-4 left-0 bottom-0 z-[2] xl:shadow-lg bg-white rounded-[20px] border-r xl:border-r-0 xl:border-b w-full max-w-[400px] scrollbar-none relative ${
                drawer?.insights ? 'slider-in' : 'slider-out'
              }`}
              style={{
                height:
                  window?.innerWidth >= 1301
                    ? '100%'
                    : `calc(100% - ${
                        window?.innerWidth >= 769 ? '197px' : '273px'
                      })`,
              }}
              onAnimationEnd={() => {
                if (!drawer?.insights) {
                  setDrawer({ ...drawer, insightsAnimation: false });
                }
              }}
            >
              <Insights
                drawer={drawer}
                filters={filters}
                setDrawer={setDrawer}
                setFilters={setFilters}
                selectDate={selectDate}
                setSelectDate={setSelectDate}
                insights={insightsData?.Insights}
              />
            </div>
          ) : (
            <div className={`flex justify-between`}>
              <button
                onClick={() => setDrawer({ ...drawer, insights: true })}
                className='h-[52px] w-[56px] bg-white rounded-r-full shadow-lg'
              >
                <TbLayoutSidebarRightCollapse
                  size={28}
                  color='#939393'
                  className='h-full m-auto'
                />
              </button>

              {/* <button
                onClick={() => setDrawer({ ...drawer, chats: !drawer?.chats })}
                className='h-[52px] hidden md:block w-[56px] bg-white rounded-l-full shadow-lg'
              >
                <TbLayoutSidebarRightExpand
                  size={28}
                  color='#939393'
                  className='h-full m-auto'
                />
              </button> */}
            </div>
          )}

          <div className='flex-1 flex flex-col gap-3 overflow-hidden md:p-4'>
            <div
              className={`tab-content flex-1 font-poppinsRegular bg-white rounded-[20px] ${
                (drawer?.chats || drawer?.insights) &&
                window?.innerWidth <= 1300
                  ? 'overflow-hidden'
                  : 'overflow-auto'
              }`}
            >
              <div className='grid grid-cols-1'>
                <div className='col-span-3 p-3 overflow-auto flex flex-col'>
                  {!drawer?.insights && (
                    <div className='relative min-h-[500px]'>
                      <MapFilters
                        filters={filters}
                        setFilters={setFilters}
                        mapViews={mapViewOptions}
                        insightsDetails={insightsData}
                        viewRootClasses='[&]:[@media(min-width:640px)_and_(max-width:660px)]:w-full'
                        classes='[&]:[@media(min-width:640px)_and_(max-width:660px)]:justify-center'
                      />
                      {data?.length > 0 && (
                        <div className='absolute z-[1] bottom-7 font-poppins p-3 left-3 bg-white'>
                          <div className='flex gap-2 mb-2'>
                            <GoDotFill className='text-red-600' />
                            <span className='text-xs'>Stopped car</span>
                          </div>
                          <div className='border-b mb-2' />
                          <div className='flex gap-2'>
                            <TbLocationFilled className='text-green-700' />
                            <span className='text-xs'>Running car</span>
                          </div>
                        </div>
                      )}

                      {data?.length > 0 ? (
                        <APIProvider
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                        >
                          <Map
                            options={{
                              restriction: {
                                latLngBounds:
                                  insightsData?.mapBounds || defaultMapBounds,
                              },
                            }}
                            zoom={zoomLevel}
                            center={mapCenter}
                            zoomControl={true}
                            fullscreenControl={true}
                            fullscreenControlOptions={{
                              position: 9.0,
                            }}
                            disableDefaultUI={true}
                            gestureHandling={'greedy'}
                            className='map h-full w-full'
                            onZoomChanged={handleZoomChange}
                            onCameraChanged={() => setMapCenter(null)}
                            defaultCenter={{ ...data?.[0]?.position }}
                            mapId={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                          >
                            <ClusteredCars
                              stateWiseCars={data}
                              stateData={stateData}
                              setStateData={setStateData}
                              setZoomLevel={setZoomLevel}
                              setMapCenter={setMapCenter}
                              onHoverDetails={hoverClusterDetails}
                              setOnHoverDetails={setHoverClusterDetails}
                            />
                          </Map>
                        </APIProvider>
                      ) : (
                        <div className='h-full flex justify-center items-center text-gray-400'>
                          Loading data...
                        </div>
                      )}
                    </div>
                  )}
                  <div className='p-5'>
                    {!drawer?.insights && data?.length > 0 && (
                      <div
                        className={`flex ${
                          drawer?.chats &&
                          drawer?.insights &&
                          '[@media(min-width:1301px)_and_(max-width:1702px)]:justify-start'
                        } ${
                          (drawer?.chats || drawer?.insights) &&
                          '[@media(min-width:1301px)_and_(max-width:1343px)]:justify-start'
                        } [@media(min-width:0px)_and_(max-width:983px)]:justify-start justify-center ${
                          mapBottomFilters &&
                          'min-h-6 mb-5 overflow-x-auto scrollbar-none'
                        }`}
                      >
                        {mapBottomFilters &&
                          mapBottomFilters.map((item, index) => {
                            const classes =
                              index === 0
                                ? 'rounded-l-full'
                                : mapBottomFilters?.length - 1 === index
                                ? 'rounded-r-full'
                                : '';

                            const selectedClusterKey =
                              filters?.activeClusterTab?.id === 1
                                ? 'parkingDays'
                                : 'odometer';
                            return (
                              <button
                                key={item?.id}
                                className={`px-5 text-nowrap py-3 h-full flex items-center tracking-wide font-poppinsRegular border-r ${classes} ${
                                  filters?.[selectedClusterKey] === item?.id
                                    ? 'text-white bg-orange'
                                    : 'text-black bg-semiLightGray'
                                }`}
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    [selectedClusterKey]: item?.id,
                                  })
                                }
                              >
                                {item?.title}
                              </button>
                            );
                          })}
                      </div>
                    )}
                    {console.log('filters', filters?.insight)}
                    <h1 className='text-2xl mb-5'>{filters?.insight?.title}</h1>

                    <div className='mb-5 font-poppinsRegular'>{filters?.insight?.summary}</div>

                    {filters?.insight?.detail &&
                      filters?.insight?.detail?.map((data) => (
                        <div className='mb-5 font-poppinsRegular'>
                          <MarkdownRenderer markdown={data} />
                        </div>
                      ))}

                    {filters?.insight?.description && (
                      <p className='mt-5 font-poppinsRegular'>
                        {filters?.insight?.description}
                      </p>
                    )}

                    {filters?.insight?.data?.length > 0 && (
                      <div className='mt-4'>
                        {filters?.insight?.data?.map((data, index) => (
                          <div className='markdown-container'>
                            <MarkdownRenderer markdown={data} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <Chats
                  drawer={drawer}
                  setDrawer={setDrawer}
                  insightData={filters?.insight}
                />
              </div>
            </div>
          </div>

          {/* {drawer?.chats || drawer?.chatsAnimation ? (
            <div
              className={`max-w-[400px] bg-white rounded-[20px] xl:shadow-lg xl:fixed mr-4 xl:mr-0 right-0 bottom-0 z-[2] overflow-hidden ${
                drawer?.chats ? 'slider-in-chat' : 'slider-out-chat'
              }`}
              style={{
                height:
                  window?.innerWidth >= 1301
                    ? '100%'
                    : `calc(100% - ${
                        window?.innerWidth >= 769 ? '197px' : '273px'
                      })`,
              }}
              onAnimationEnd={() => {
                if (!drawer?.chats) {
                  setDrawer({ ...drawer, chatsAnimation: false });
                }
              }}
            >
              <Chats
                drawer={drawer}
                setDrawer={setDrawer}
                insightData={filters?.insight}
              />
            </div>
          ) : (
            <button
              onClick={() => setDrawer({ ...drawer, chats: true })}
              className='h-[52px] block md:hidden w-[56px] bg-white rounded-l-full shadow-lg'
            >
              <TbLayoutSidebarRightExpand
                size={28}
                color='#939393'
                className='h-full m-auto'
              />
            </button>
          )} */}
        </div>
      )}
    </div>
  );
};

export default RepoPanel;
