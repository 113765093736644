import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import {
  TbLayoutSidebarRightCollapse,
  TbLocationFilled,
} from 'react-icons/tb';
import { ENDPOINTS } from '../api/endpoints';
import { formatDate } from '../helpers/commonFunctions';
import { ClusteredCars } from '../components/ClusteredCars';
import {
  finDashTabs,
  daysOfParking,
  odometerFilter,
} from '../helpers/constants';
import Chats from '../components/Chats';
import Insights from '../components/Insights';
import MapFilters from '../components/MapFilters';
import TabsAndSearch from '../components/TabsAndSearch';
import { GoDotFill } from 'react-icons/go';

const defaultFilters = {
  state: null,
  model: null,
  search: null,
  insight: null,
  selectedCar: null,
  mapState: 'Map View',
  odometer: odometerFilter?.[0]?.id,
  activeClusterTab: finDashTabs?.[0],
  parkingDays: daysOfParking?.[0]?.id,
};

const viewOptions = ['Map View', 'List View'];

const FinDash = () => {
  const socketRef = useRef(null);

  const [drawer, setDrawer] = useState({
    chats: false,
    insights: false,
    chatsAnimation: false,
    insightsAnimation: false,
  });
  const [data, setMapData] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [mapCenter, setMapCenter] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectDate, setSelectDate] = useState([new Date(), new Date()]);
  const [insightData, setInsightData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [hoverClusterDetails, setHoverClusterDetails] = useState(null);

  const handleZoomChange = ({ detail }) => {
    setZoomLevel(detail?.zoom);
    if (detail?.zoom <= 5) {
      setStateData(null);
      setHoverClusterDetails(null);
    }
  };

  useEffect(() => {
    const [startdate, enddate] = selectDate;

    const createSocket = () => {
      const params = {
        panel_type: 'findash',
        mapType: filters?.mapState,
        enddate: formatDate(enddate),
        startdate: formatDate(startdate),
        insight_id: filters?.insight?.id,
        page_name: filters?.activeClusterTab?.key,
      };
      const queryParams = new URLSearchParams(params).toString();
      const newSocket = new WebSocket(
        `wss://demo.assetwatch.info/ws/updates?${queryParams}`
      );

      newSocket.onopen = () => {
        console.log('Socket connected');
        socketRef.current = newSocket;

        const message = {
          dpd_filter: filters?.dpd,
          model_filter: filters?.model,
          map_type: filters?.mapState,
        };
        if (filters?.activeClusterTab) newSocket.send(JSON.stringify(message));
      };

      newSocket.onmessage = ({ data }) => {
        setMapData(JSON.parse(data));
      };

      return () => newSocket.close();
    };

    if (enddate && startdate && filters?.insight && filters?.mapState) {
      createSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [filters?.insight, filters?.mapState, filters?.model]);

  useEffect(() => {
    if (socketRef.current) {
      const message = {
        model_filter: filters?.model,
      };
      socketRef.current.send(JSON.stringify(message));
    }
  }, [filters?.model]);

  useEffect(() => {
    if (stateData) {
      const findStateData = data?.find(
        (state) => state?.key === stateData?.key
      );
      if (findStateData) {
        setStateData(findStateData);
      }
    }
  }, [data]);

  useEffect(() => {
    let apiRoutes = null;
    switch (filters?.activeClusterTab?.id) {
      case 0:
        apiRoutes =
          filters?.mapState === 'List View'
            ? ENDPOINTS?.OVERVIEW_TABLE_DATA
            : ENDPOINTS?.OVERVIEW_DATA;
        break;
      case 1:
        apiRoutes = ENDPOINTS?.USAGE_DATA;
        break;
      case 2:
        apiRoutes = ENDPOINTS?.DEMOGRAPHY_DATA;
        break;
      default:
        setInsightData(null);
    }

    const [startdate, enddate] = selectDate;

    if (apiRoutes && startdate && enddate) {
      (async () => {
        try {
          const payload = {
            startdate: formatDate(startdate),
            enddate: formatDate(enddate),
          };

          if (filters?.insight) payload.insight_id = filters?.insight?.id;

          if (filters?.model) payload.model = filters?.model;
          setLoading(true)
          const response = await axios.post(apiRoutes, payload);

          const data = response?.data;
          setInsightData(data);

          const updatefilters = {};

          if (!filters?.model)
            updatefilters.model = data?.modelsFilter?.[0]?.value;

          if (!filters?.insight) updatefilters.insight = data?.Insights?.[0];

          if (Object.keys(updatefilters)?.length > 0)
            setFilters({ ...filters, ...updatefilters });
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log('Error fetching insights:', error);
        }
      })();
    }
  }, [
    filters?.activeClusterTab,
    filters?.mapState,
    selectDate,
    filters?.model,
  ]);

  return (
    <div
      className={`rounded-xl border h-full flex flex-col ${
        (drawer?.chats || drawer?.insights) && window?.innerWidth <= 1301
          ? '!h-[calc(100vh-100px)]'
          : 'h-full'
      }`}
    >
      <TabsAndSearch
        loading={loading}
        setFilters={setFilters}
        filters={filters}
        clusterTabs={finDashTabs}
      />
      <div
        className={`flex-1 flex md:flex-col gap-3 overflow-hidden pt-3 ${
          drawer?.chats || drawer?.insights ? 'mb-0' : 'mb-8'
        }`}
      >
        {drawer?.insights || drawer?.insightsAnimation ? (
          <div
            className={`xl:fixed xl:ml-0 ml-4 left-0 bottom-0 z-[2] xl:shadow-lg bg-white rounded-[20px] border-r xl:border-r-0 xl:border-b w-full max-w-[400px] scrollbar-none relative ${
              drawer?.insights ? 'slider-in' : 'slider-out'
            }`}
            style={{
              height:
                window?.innerWidth >= 1301
                  ? '100%'
                  : `calc(100% - ${
                      window?.innerWidth >= 769 ? '197px' : '273px'
                    })`,
            }}
            onAnimationEnd={() => {
              if (!drawer?.insights) {
                setDrawer({ ...drawer, insightsAnimation: false });
              }
            }}
          >
            <Insights
              drawer={drawer}
              filters={filters}
              setDrawer={setDrawer}
              setFilters={setFilters}
              selectDate={selectDate}
              setSelectDate={setSelectDate}
              insights={insightData?.Insights}
            />
          </div>
        ) : (
          <div className='flex justify-between'>
            <button
              onClick={() => setDrawer({ ...drawer, insights: true })}
              className='h-[52px] w-[56px] bg-white rounded-r-full shadow-lg'
            >
              <TbLayoutSidebarRightCollapse
                size={28}
                color='#939393'
                className='h-full m-auto'
              />
            </button>

              {/* <button
              onClick={() => setDrawer({ ...drawer, chats: !drawer?.chats })}
              className='h-[52px] hidden md:block w-[56px] bg-white rounded-l-full shadow-lg'
            >
              <TbLayoutSidebarRightExpand
                size={28}
                color='#939393'
                className='h-full m-auto'
              />
            </button> */}
          </div>
        )}
        <div className='flex-1 flex flex-col gap-3 overflow-hidden md:p-4'>
          <div
            className={`tab-content flex-1 font-poppinsRegular bg-white rounded-[20px] ${
              (drawer?.chats || drawer?.insights) && window?.innerWidth <= 1300
                ? 'overflow-hidden'
                : 'overflow-auto'
            }`}
          >
            <div className='grid grid-cols-1'>
              <div className='col-span-3 p-3 overflow-auto flex flex-col'>
                {!drawer?.insights && (
                  <div
                    className={`relative rounded-[20px] ${
                      filters?.mapState !== 'Map View'
                        ? 'min-h-fit'
                        : 'min-h-[500px]'
                    }`}
                  >
                    <MapFilters
                      drawer={drawer}
                      filters={filters}
                      setDrawer={setDrawer}
                      setFilters={setFilters}
                      insightsDetails={insightData}
                      mapViews={
                        filters?.activeClusterTab?.id === 0 && viewOptions
                      }
                      viewRootClasses={`${
                        filters?.mapState === 'Map View' &&
                        '[&]:sm:w-fit [&]:xxxs:w-full'
                      }`}
                      classes={`${
                        filters?.mapState !== 'Map View'
                          ? '[&&]:relative'
                          : '[&]:sm:flex-row xxxs:!justify-center [&]:[@media(min-width:376px)_and_(max-width:471px)]:justify-center'
                      }`}
                    />
                    {filters?.activeClusterTab?.id === 0 ? (
                      filters?.mapState === 'Map View' ? (
                        data?.length > 0 && (<>
                          <div className='absolute z-[1] bottom-7 font-poppins p-3 left-3 bg-white'>
                            <div className='flex gap-2 mb-2'>
                              <GoDotFill className='text-red-600' />
                              <span className='text-xs'>Stopped car</span>
                            </div>
                            <div className='border-b mb-2' />
                            <div className='flex gap-2'>
                              <TbLocationFilled className='text-green-700' />
                              <span className='text-xs'>Running car</span>
                            </div>
                          </div>
                          <APIProvider
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                          >
                            <Map
                              options={{
                                restriction: {
                                  latLngBounds: insightData?.mapBounds,
                                },
                              }}
                              zoom={zoomLevel}
                              disableDefaultUI
                              center={mapCenter}
                              zoomControl={true}
                              gestureHandling={'greedy'}
                              className='map h-full w-full'
                              onZoomChanged={handleZoomChange}
                              onCameraChanged={() => setMapCenter(null)}
                              defaultCenter={{ ...data?.[0]?.position }}
                              mapId={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                            >
                              <ClusteredCars
                                stateWiseCars={data}
                                stateData={stateData}
                                isFinDash={true}
                                setZoomLevel={setZoomLevel}
                                setMapCenter={setMapCenter}
                                setStateData={setStateData}
                                onHoverDetails={hoverClusterDetails}
                                setOnHoverDetails={setHoverClusterDetails}
                              />
                            </Map>
                          </APIProvider>
                        </>)
                      ) : (
                        <div className='bg-white p-6 rounded-lg'>
                          <div className='overflow-x-auto'>
                            <div className='min-w-full overflow-x-auto rounded-xl border border-gray-200 scrollbar-none'>
                              <table className='min-w-full bg-white'>
                                <thead className='bg-gray-50'>
                                  <tr>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      Odometer Range (Miles)
                                    </th>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      Vehicles (Count)
                                    </th>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      In-State Vehicles
                                    </th>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      Out-of-State Vehicles
                                    </th>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      Onboarded
                                    </th>
                                    <th className='px-4 py-2 border-b border-r border-gray-200'>
                                      De-fleeted
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {insightData?.tableView?.map((col) => (
                                    <tr key={col?.odometerRange}>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.odometerRange}
                                      </td>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.vehicles}
                                      </td>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.inState}
                                      </td>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.outState}
                                      </td>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.onboarded}
                                      </td>
                                      <td className='px-4 py-2 border-b border-r border-gray-200'>
                                        {col?.deFleeted}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <ResponsiveContainer
                        width='100%'
                        className={`mt-16`}
                        height={400}
                      >
                        <BarChart
                          width={600}
                          height={300}
                          data={insightData?.chartData}
                          margin={{
                            top: 20,
                            left: 20,
                            right: 30,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis dataKey='name' />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey='0-500' stackId='a' fill='#82ca9d' />
                          <Bar dataKey='500-1K' stackId='a' fill='#8884d8' />
                          <Bar dataKey='1K-1.5K' stackId='a' fill='#ffc658' />
                          <Bar dataKey='1.5K-2K' stackId='a' fill='#8dd1e1' />
                          <Bar dataKey='2K+' stackId='a' fill='#ff8042' />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                )}
                <div className='p-5'>
                  <h1 className='text-2xl mb-5'>{filters?.insight?.title}</h1>

                  {filters?.insight?.description && (
                    <p className='mt-5 font-poppinsRegular'>
                      {filters?.insight?.description}
                    </p>
                  )}

                  {filters?.insight?.data?.length > 0 && (
                    <ul className='mt-4 list-disc'>
                      {filters?.insight?.data?.map((data, index) => (
                        <li key={index} className='ms-4'>
                          {data}
                        </li>
                      ))}
                    </ul>
                  )}

                  {insightData?.common && (
                    <div className='mt-4'>
                      Common :
                      {insightData?.common?.map((data) => (
                        <div className='ms-10'>{data}</div>
                      ))}
                    </div>
                  )}
                </div>
                <Chats
                  drawer={drawer}
                  setDrawer={setDrawer}
                  insightData={filters?.insight}
                />
              </div>
            </div>
          </div>
        </div>

        {/* {drawer?.chats || drawer?.chatsAnimation ? (
          <div
            className={`max-w-[400px] xl:fixed xl:shadow-lg bg-white rounded-[20px] right-0 bottom-0 z-[2] overflow-hidden ${
              drawer?.chats ? 'slider-in-chat' : 'slider-out-chat'
            }`}
            style={{
              height:
                window?.innerWidth >= 1301
                  ? '100%'
                  : `calc(100% - ${
                      window?.innerWidth >= 769 ? '197px' : '273px'
                    })`,
            }}
            onAnimationEnd={() => {
              if (!drawer?.chats) {
                setDrawer({ ...drawer, chatsAnimation: false });
              }
            }}
          >
            <Chats
              drawer={drawer}
              setDrawer={setDrawer}
              insightData={filters?.insight}
            />
          </div>
        ) : (
          <button
            onClick={() => setDrawer({ ...drawer, chats: true })}
            className='h-[52px] block md:hidden w-[56px] bg-white rounded-l-full shadow-lg'
          >
            <TbLayoutSidebarRightExpand
              size={28}
              color='#939393'
              className='h-full m-auto'
            />
          </button>
        )} */}
      </div>
    </div>
  );
};

export default FinDash;
