import React, { useState } from 'react';
import Popup from 'reactjs-popup';

import { useDispatch } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import { PiLifebuoy } from 'react-icons/pi';
import { FaChevronDown } from 'react-icons/fa';
import { IoIosSettings } from 'react-icons/io';
import { useLocation, Link } from 'react-router-dom';

import { sidebarItems, terminalProductsList } from '../helpers/constants';
import {
  changeIsCollapseStatus,
  resetSearch,
} from '../redux/slices/profileSlice';

function SidebarList() {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = location?.pathname;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  return (
    <div className='xl:absolute bg-orange xl:block hidden min-h-screen z-[99] smoothTransition max-w-[300px] w-full top-[100px] sm:top-[142px]'>
      <ul className='text-white'>
        {sidebarItems?.map((item, index) => {
          return (
            <li
              key={index}
              className={`text-base
                        ${
                          pathname === item?.match
                            ? 'bg-slate-50/10 font-semibold'
                            : 'text-lime-50 hover:bg-slate-50/10'
                        }`}
              onClick={() => {
                if (item?.children?.length) {
                  setIsOpenDropDown(!isOpenDropDown);
                }
              }}
            >
              <Link
                to={item?.path}
                onClick={() => {
                  dispatch(resetSearch()); dispatch(changeIsCollapseStatus());
                }}
              >
                <div className='flex gap-2 py-4 px-5 items-center justify-between'>
                  <div className='flex gap-2 items-center'>
                    <div>{item?.icon}</div>
                    <div>{item?.name}</div>
                  </div>
                  {item?.children?.length && (
                    <div
                      className={`text-sm cursor-pointer ${
                        isOpenDropDown ? 'rotate-180' : ''
                      }`}
                    >
                      <FaChevronDown />
                    </div>
                  )}
                </div>
              </Link>
              {item?.children?.length && (
                <>
                  <div
                    className={`${
                      isOpenDropDown ? 'h-full' : 'h-0'
                    } overflow-hidden`}
                  >
                    {item?.children?.map((item1) => {
                      return (
                        <ul className='indent-8 mt-2'>
                          <li className='text-[16px]'>{item1?.name}</li>
                        </ul>
                      );
                    })}
                  </div>
                </>
              )}
            </li>
          );
        })}

        <li className='flex gap-3 justify-center mt-5'>
          <div className='border rounded-full border-[#FFFFFF33] cursor-pointer'>
            <IoIosSettings className='text-2xl m-4' />
          </div>

          <div
            onClick={() => setOpen((o) => !o)}
            className='border rounded-full border-[#FFFFFF33] cursor-pointer'
          >
            <PiLifebuoy className='text-2xl m-4' />
          </div>

          <Popup
            open={open}
            lockScroll
            closeOnEscape
            closeOnDocumentClick
            onClose={closeModal}
            contentStyle={{ width: 'fit-content', maxWidth: '80%' }}
          >
            <div className='p-3 relative'>
              <button
                onClick={closeModal}
                className='border rounded-lg absolute top-3 right-3'
              >
                <IoMdClose size={20} />
              </button>
              {terminalProductsList?.map((item) => {
                return (
                  <div className='mb-5'>
                    <h2 className='font-bold mb-3'>{item?.title}</h2>
                    <div className='flex flex-wrap gap-2'>
                      {item?.products?.length > 0
                        ? item?.products?.map((product) => {
                            return (
                              <Link
                                to={product?.link}
                                key={item?.name}
                                className={`border bg-[#FAFAFA] rounded-xl p-3 text-sm ${
                                  product?.link
                                    ? 'hover:bg-peach hover:text-orange hover:border-orange cursor-pointer'
                                    : 'bg-[#e7e6e6] border-[#c6c6c6] cursor-not-allowed'
                                }`}
                              >
                                {product?.title}
                              </Link>
                            );
                          })
                        : ''}
                    </div>
                  </div>
                );
              })}
            </div>
          </Popup>
        </li>
      </ul>
    </div>
  );
}

export default SidebarList;
