import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';

import { CarMarker } from './CarMarker';
import { ReactComponent as OdometerIcon } from '../assets/svg/street-view.svg';
import { ReactComponent as Call } from '../assets/svg/call.svg';

export const ClusteredCars = ({
  stateWiseCars,
  stateData,
  isFinDash,
  isSearchMap,
  setMapCenter,
  setStateData,
  setZoomLevel,
  onHoverDetails,
  setOnHoverDetails,
}) => {
  const map = useMap();

  const [markers, setMarkers] = useState({});
  const [finDashHoverDetails, setFinDashHoverDetails] = useState(null);

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  const data = stateData ? stateData?.cars : stateWiseCars;

  useEffect(() => {
    if (!clusterer) return;

    clusterer?.clearMarkers();
    clusterer?.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  useEffect(() => {
    const handleMapClick = () => {
      setOnHoverDetails(null);
      setFinDashHoverDetails(null);
    };

    if (map) {
      map?.addListener('click', handleMapClick);
    }

    return () => {
      if (map && map.removeEventListener) {
        map?.removeEventListener('click', handleMapClick);
      }
    };
  }, [map, setOnHoverDetails]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers?.[key]) || (!marker && !markers?.[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
      {isSearchMap ? (
        <CarMarker
          car={stateWiseCars}
          isFinDash={isFinDash}
          stateData={stateData}
          isSearchMap={isSearchMap}
          setStateData={setStateData}
          setMarkerRef={setMarkerRef}
          key={`${stateWiseCars?.key}`}
          onHandle={() => setOnHoverDetails(stateWiseCars)}
        />
      ) : (
        data?.map((car, index) => (
          <CarMarker
            car={car}
            isFinDash={isFinDash}
            stateData={stateData}
            key={`${car?.key}${index}`}
            setZoomLevel={setZoomLevel}
            setMapCenter={setMapCenter}
            setStateData={setStateData}
            setMarkerRef={setMarkerRef}
            onHandle={() => setOnHoverDetails(car)}
            setFinDashHoverDetails={setFinDashHoverDetails}
          />
        ))
      )}

      {onHoverDetails && (
        <InfoWindow
          headerDisabled={false}
          anchor={markers[onHoverDetails?.key]}
          onCloseClick={() => setOnHoverDetails(null)}
          className='p-3'
        >
          <div className='flex sm:flex-col-reverse min-w-[320px]'>
            <div className='sm:mb-3 my-auto'>
              <div className='font-semibold'>{onHoverDetails?.carModel}</div>
              <div className='text-[#4A4D54] break-all pt-2'>
                {onHoverDetails?.carNumber}
              </div>
            </div>
            {onHoverDetails?.carImg && (
              <img
                className='h-[64px] m-auto'
                src={onHoverDetails?.carImg}
                alt='car'
              />
            )}
          </div>

          <hr />
          <div className='flex py-3 gap-5'>
            <FaLocationDot size={20} color='#CDCDCD' />
            <div className='text-xs text-[12px] my-auto'>
              {onHoverDetails?.address}
            </div>
          </div>
          <hr />

          <div className='flex gap-2 items-centers mt-4 mb-7 justify-between'>
            <div className='flex gap-2 items-center'>
            </div>
          </div>

          <div className='flex justify-between'>
            <button className='border flex gap-[10px] py-[10px] px-[15px] rounded-[10px] items-center '>
              <OdometerIcon />
              Street View
            </button>
            <button className='bg-[#CD4132] text-white px-7 py-[10px] rounded-[10px]'>
              Vehicle info
            </button>
          </div>
        </InfoWindow>
      )}

      {finDashHoverDetails && (
        <InfoWindow
          headerDisabled={!isFinDash}
          headerContent={
            <div className='font-bold'>{finDashHoverDetails?.state}</div>
          }
          anchor={markers[finDashHoverDetails?.key]}
          onCloseClick={() => setFinDashHoverDetails(null)}
          className='pb-[3px] pt-1 pl-[7px]'
        >
          {isFinDash ? (
            <ul className='list-disc pl-5 space-y-2'>
              <li>{finDashHoverDetails?.onboarded} Onboarded</li>
              <li>{finDashHoverDetails?.deFleeted} De-fleeted</li>
              <li>{finDashHoverDetails?.outOfState} Out-of-state</li>
              <li>{finDashHoverDetails?.outSideState} Outside state</li>
            </ul>
          ) : (
            <div className='font-bold -p-1'>{finDashHoverDetails?.state}</div>
          )}
        </InfoWindow>
      )}
    </>
  );
};
