import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userType: 'admin',
  isCollapse: false,
  isMobile: window?.innerWidth <= 768,
  search: {
    cars: null,
    selectedCar: null,
    selectedVehicleTrack: null,
    searchValue: ""
  },
};

export const profileSlice = createSlice({
  name: 'profileData',
  initialState,
  reducers: {
    changeIsCollapseStatus(state, { payload }) {
      state.isCollapse = payload;
    },
    changeIsMobileStatus(state, { payload }) {
      state.isMobile = payload;
    },

    changeSearchValue(state, { payload }) {
      state.search.searchValue = payload;
    },
    searchValue(state, { payload }) {
      state.search.cars = payload;
    },
    searchSelectedCar(state, { payload }) {
      state.search.selectedCar = payload;
    },
    changeSelectedVehicleTracking(state, { payload }) {
      state.search.selectedVehicleTrack = payload;
    },
    resetSearch(state) {
      state.search.cars = null;
      state.search.selectedCar = null;
      state.search.selectedVehicleTrack = null;
      state.search.searchValue = "";
    },
  },
});

export const {
  searchValue,
  resetSearch,
  searchSelectedCar,
  changeIsMobileStatus,
  changeIsCollapseStatus,
  changeSelectedVehicleTracking,
  changeSearchValue
} = profileSlice.actions;

export default profileSlice.reducer;
