export const ENDPOINTS = {
  CLUSTERS_DATA: '/api/v1/repopanel/clusters/data',
  ODOMETER_DATA: '/api/v1/repopanel/odometer/data',
  NIGHTLY_PARKING_DATA: '/api/v1/repopanel/nightly_parking/data',

  USAGE_DATA: '/api/v1/findash/usage/data',
  OVERVIEW_DATA: '/api/v1/findash/overview/data',
  DEMOGRAPHY_DATA: '/api/v1/findash/demograph/data',
  OVERVIEW_TABLE_DATA: '/api/v1/findash/overview/table_data',

  SEARCH_DATA: '/api/v1/search/data',

  REPORT_INSIGHTS: '/api/v1/reports/report/insights',
  REPORT_DATA: '/api/v1/reports/report/data',
};
