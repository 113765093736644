import React, { useState } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import Status from './Search/Status';
import TabsAndSearch from './TabsAndSearch';
import { searchTabData } from '../helpers/constants';
import { searchSelectedCar } from '../redux/slices/profileSlice';
import MarkdownRenderer from 'react-markdown-renderer';

const Search = () => {
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.profileData.search);

  const carDetails = searchResult?.selectedCar;

  const [drawer, setDrawer] = useState({
    cars: true,
    carsAnimation: false,
  });
  const [activeTab, setActiveTab] = useState(0);

  const resetSearch = () => {
    if (carDetails) {
      dispatch(searchSelectedCar(null));
      setActiveTab(0);
    }
  };

  return (
    <div className='rounded-xl border h-full flex flex-col'>
      <span className='ml-4 mt-3 flex gap-2 w-fit p-2'>
        <button className='text-gray-500' onClick={resetSearch}>
          Vehicles
        </button>
        {carDetails && (
          <span className='flex gap-2 text-orange items-center'>
            {' '}
            <FaArrowRightLong /> {carDetails?.carModel}
          </span>
        )}
      </span>
      <TabsAndSearch
        isSearch={true}
        filters={activeTab}
        setFilters={setActiveTab}
        clusterTabs={searchTabData}
      />
      {activeTab === 0 ? (
        <Status
          setDrawer={setDrawer}
          drawer={drawer}
          searchResult={searchResult}
        />
      ) : activeTab === 1 ? (
        // <Trips searchResult={searchResult} />
        <div className='overflow-x-auto m-4 bg-white p-5 scrollbar-nonem-4 rounded-[20px]'>
          {carDetails ? (
            <p className='mt-5 font-poppinsRegular leading-8'>
              <MarkdownRenderer markdown={carDetails?.trips} />
            </p>
          ) : (
            <div className='text-center'>
              Please select the car you want to see details for.
            </div>
          )}
          {/* {carDetails?.trips} */}
        </div>
      ) : activeTab === 2 ? (
        <div className='overflow-x-auto m-4 bg-white p-5 scrollbar-nonem-4 rounded-[20px]'>
          {carDetails ? (
            <p className='mt-5 font-poppinsRegular leading-8'>
              <MarkdownRenderer markdown={carDetails?.event} />
            </p>
          ) : (
            <div className='text-center'>
              Please select the car you want to see details for.
            </div>
          )}
        </div>
      ) : activeTab === 3 ? (
        <div className='overflow-x-auto m-4 bg-white p-5 scrollbar-nonem-4 rounded-[20px]'>
          {/* <h1 className='text-2xl mb-5'>{searchResult?.valuation}</h1> */}

          {/* {searchResult?.selectedCar?.description && ( */}
          {carDetails ? (
            <p className='mt-5 font-poppinsRegular leading-8'>
              <MarkdownRenderer markdown={carDetails?.valuation} />
            </p>
          ) : (
            <div className='text-center'>
              Please select the car you want to see details for.
            </div>
          )}
          {/* )} */}
        </div>
      ) : (
        <div className='overflow-x-auto m-4 bg-white p-5 scrollbar-nonem-4 rounded-[20px]'>
          {carDetails ? (
            <p className='mt-5 font-poppinsRegular leading-8'>
              <MarkdownRenderer markdown={carDetails?.event} />
            </p>
          ) : (
            <div className='text-center'>
              Please select the car you want to see details for.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
