import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const SignIn = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  const handlerOnChange = (e) => {
    const { value, name } = e?.target;
    setData({ ...data, [name]: value });
  };

  const handlerSubmit = () => {
    navigate('/repo-panel');
  };
  return (
    <div className='flex items-center justify-center p-0 sm:p-4 min-h-screen h-full bg-peach font-poppinsRegular'>
      <div className='w-full max-w-md p-8 bg-white rounded-lg shadow-lg'>
        <div className='flex bg-peach justify-center gap-3 items-center rounded-lg p-4'>
          <div className='flex items-center justify-center'>
            <img
              src='/assets/images/main-logo.png'
              alt='Company Logo'
              className='w-20 h-20 object-cover'
            />
          </div>
          <h1 className='text-2xl font-bold text-orange text-center'>
            Asset Watch
          </h1>
        </div>
        <h2 className='text-xl font-semibold text-orange my-3 text-center'>
          Sign In
        </h2>
        <form onSubmit={handlerSubmit}>
          <div className='mb-4'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='email'
            >
              Email
            </label>
            <input
              required
              type='email'
              id='email'
              name='email'
              placeholder='Your Email'
              onChange={handlerOnChange}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus-visible:outline-orange'
            />
          </div>
          <div className='mb-6'>
            <label
              className='block text-gray-700 text-sm font-bold mb-2'
              htmlFor='password'
            >
              Password
            </label>
            <input
              required
              id='password'
              type='password'
              name='password'
              placeholder='Your Password'
              onChange={handlerOnChange}
              className='w-full px-3 py-2 border border-gray-300 rounded-md focus-visible:outline-orange'
            />
          </div>
          <button
            type='submit'
            className='w-full py-2 px-4 bg-orange text-white rounded-md hover:bg-darkOrange'
          >
            Sign In
          </button>
        </form>
        <div className='mt-4 text-center'>
          <p className='text-gray-700'>Don't have an account?</p>
          <Link to='/sign-up' className='text-orange font-semibold'>
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
