import React, { useCallback } from 'react';

import { GoDotFill } from 'react-icons/go';
import { TbLocationFilled } from 'react-icons/tb';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

export const CarMarker = ({
  car,
  onHandle,
  isFinDash,
  stateData,
  isSearchMap,
  setZoomLevel,
  setMarkerRef,
  setMapCenter,
  setStateData,
  setFinDashHoverDetails,
}) => {
  const ref = useCallback(
    (marker) => setMarkerRef(marker, car?.key),
    [setMarkerRef, car?.key]
  );

  const handleStateClick = (stateCars) => {
    setZoomLevel(7);
    setStateData(stateCars);
    setFinDashHoverDetails(null);
    setMapCenter(stateCars?.position);
  };

  const getBgColor = (length) => {
    if (length >= 100) return 'bg-red-700';
    if (length >= 10) return 'bg-yellow-500';
    if (length >= 1) return 'bg-blue-500';
    return 'bg-black';
  };

  return (
    <>
      <AdvancedMarker position={car?.position} ref={ref} onClick={() => {}}>
        {stateData || isSearchMap ? (
          <div className='text-2xl' onClick={() => onHandle(car)}>
            {car?.isStopped ? (
              <GoDotFill className='text-red-600' />
            ) : (
              <TbLocationFilled className='text-green-700' />
            )}
          </div>
        ) : (
          <div
            className={`${
              isFinDash ? 'bg-pink-300' : getBgColor(car?.cars?.length)
            } h-10 w-10 shadow-md flex items-center justify-center text-white rounded-full`}
            onClick={() => handleStateClick(car)}
            onMouseEnter={() => setFinDashHoverDetails(car)}
          >
            {isFinDash ? (
              <div className='flex flex-col'>
                <span className='text-black text-center'>{car?.totalCars}</span>
                <span className='text-black text-center'>({car?.inState})</span>
              </div>
            ) : (
              car?.cars?.length
            )}
          </div>
        )}
      </AdvancedMarker>
    </>
  );
};
