import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import axiosInstance from './api/axiosInstance';

import { store } from './redux/store';

import './custom.css';
import 'reactjs-popup/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';

axios.defaults.baseURL = axiosInstance?.defaults?.baseURL;
axios.defaults.headers = axiosInstance?.defaults?.headers;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
