export function calculateBounds(markers) {
  try {
    const bounds = new window.google.maps.LatLngBounds();
    // const maps = new window.google.maps.LatLngBounds();

    markers?.forEach((marker) => {
      bounds?.extend(new window.google.maps.LatLng(marker?.lat, marker?.lng));
    });
    // map.fitBounds(bounds);
    return bounds;
  } catch (error) {
    return {};
  }
}

export function getZoomPrecise(map, array) {
  const bounds = new window.google.maps.LatLngBounds();
  array?.forEach((location) => {
    bounds.extend({ lat: parseFloat(location?.lat), lng: parseFloat(location?.lng) });
  });
  map.fitBounds(bounds);
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, '0');
  const day = String(date?.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
