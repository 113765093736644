import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BsArrowUpCircleFill } from 'react-icons/bs';

import Search from '../components/Search';
import HeaderMain from '../components/Header/HeaderMain';
import SidebarMain from '../components/SidebarMain';

function Layout() {
  const outletRef = useRef(null);
  const searchResult = useSelector((state) => state.profileData.search);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = outletRef?.current?.scrollTop > 200;
      setScrolled(isScrolled);
    };

    if (outletRef?.current) {
      outletRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (outletRef?.current) {
        outletRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className='flex font-poppinsRegular relative'>
      <SidebarMain />
      <div className={`smoothTransition w-full`}>
        <HeaderMain />
        <div ref={outletRef} className={`xl:h-auto h-[calc(100vh-100px)] bg-[#F6F6F6]`}>
          {(searchResult?.cars?.length > 0 || searchResult?.searchValue) ? <Search /> : <Outlet />}
        </div>
      </div>
      <div
        className={`scrollTop fixed bottom-7 ${
          scrolled ? 'block' : 'hidden'
        } text-darkBlue/50 hover:text-darkBlue hover:animate-bounce transition-all duration-200 right-5 cursor-pointer`}
        onClick={() => outletRef.current.scrollTo(0, 0)}
      >
        <BsArrowUpCircleFill size={40} />
      </div>
    </div>
  );
}

export default Layout;
