import ReactDatePicker from 'react-datepicker';
import { IoIosArrowBack } from 'react-icons/io';
import { LuCalendarDays } from 'react-icons/lu';

const Insights = ({
  insights,
  setFilters,
  filters,
  selectDate,
  setSelectDate,
  setDrawer,
  drawer,
}) => {
  const calendarContainer = ({ className, children }) => (
    <div className={`${className} !flex absolute`}>{children}</div>
  );

  return (
    <div className='p-5 w-full h-full relative'>
      <div className='flex justify-between'>
        <h1 className='text-2xl'>Insights</h1>
        <button
          className=''
          onClick={() =>
            setDrawer({ ...drawer, insights: false, insightsAnimation: true })
          }
        >
          <IoIosArrowBack color='#939393' fontSize={24} />
        </button>
      </div>

      <div className='flex-shrink-0 w-full my-5'>
        <ReactDatePicker
          isClearable
          selectsRange
          monthsShown={2}
          dateFormat={'MM-dd-YYYY'}
          selected={selectDate?.[0] || null}
          endDate={selectDate?.[1] || null}
          startDate={selectDate?.[0] || null}
          clearButtonClassName='after:!bg-maroon'
          onChange={(date) => setSelectDate(date)}
          calendarClassName='!border !border-[#DEDEDE] !rounded-lg'
          popperClassName='!border !border-[#DEDEDE] !z-[9] !left-14 !rounded-lg'
          className='!pl-9 py-2 border font-normal text-sm border-gray-300 text-[#242424] rounded-lg shadow-sm focus:outline-none focus:ring-0 font-poppinsRegular w-full h-[48px]'
          showIcon
          icon={
            <LuCalendarDays
              fontSize={14}
              className='absolute text-[#242424] left-1 top-1/2 transform -translate-y-1/2'
            />
          }
          calendarContainer={calendarContainer}
        />
      </div>

      <div className='space-y-5 h-full overflow-auto pb-28 scrollbar-none'>
        {insights?.length ? (
          insights?.map((item) => {
            return (
              <button
                className={`flex-row text-left w-full border ${
                  filters?.insight?.id === item?.id
                    ? 'border-orange'
                    : 'border-[#0000001F]'
                } hover:border-orange flex rounded-2xl gap-5 p-5 overflow-hidden cursor-pointer`}
                key={item?.id}
                onClick={() => setFilters({ ...filters, insight: item })}
              >
                <div className='flex-1'>
                  <h4
                    className={`${
                      filters?.insight?.id === item?.id
                        ? 'font-semibold text-xl'
                        : 'text-[#4A4D54] text-lg'
                    } text-black`}
                  >
                    {item?.title}
                  </h4>
                  <p className='text-[#4A4D54] my-2'>{item?.summary || item?.description}</p>
                  <div className='flex justify-between'>
                    <h5 className='font-poppinsRegular text-[#ADB1B7]'>Date</h5>
                    <h5 className='font-poppinsSemibold text-[#4A4D54]'>
                      {item?.date}
                    </h5>
                  </div>
                </div>
              </button>
            );
          })
        ) : (
          <div className='text-center mt-10 text-gray-400'>
            No insights data found!{' '}
          </div>
        )}
      </div>
    </div>
  );
};

export default Insights;
