import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';

import { GoSearch } from 'react-icons/go';
import { PiLifebuoy } from 'react-icons/pi';
import { IoIosSettings } from 'react-icons/io';
import { IoIosArrowDown } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';

import {
  resetSearch,
  searchValue,
  changeIsCollapseStatus,
  changeSearchValue,
} from '../../redux/slices/profileSlice';
import { ENDPOINTS } from '../../api/endpoints';
import { sidebarItems, terminalProductsList } from '../../helpers/constants';
import { debounce } from '../../helpers/commonFunctions';

export default function HeaderMain() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isCollapse, search: searchData } = useSelector(
    (state) => state.profileData
  );

  const [search, setSearch] = useState('');

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value) {
        (async () => {
          const response = await axios.post(ENDPOINTS?.SEARCH_DATA, {
            search: value,
          });
          dispatch(changeSearchValue(value));
          dispatch(searchValue(response?.data));
        })();
      } else {
        dispatch(resetSearch(null));
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    debouncedSearch(search);
  }, [search, debouncedSearch]);

  useEffect(() => {
    if (!searchData?.cars) {
      setSearch('');
    }
  }, [searchData]);

  return (
    <div className='flex sm:flex-wrap bg-orange sm:py-3 xl:h-auto min-h-[100px] h-[100px] justify-between w-full px-5 text-white overflow-hidden'>
      <div className='flex items-center gap-8'>
        <div
          role='button'
          className='xl:block hidden'
          onClick={() => dispatch(changeIsCollapseStatus(!isCollapse))}
        >
          {!isCollapse ? (
            <MdKeyboardDoubleArrowRight className='text-2xl' />
          ) : (
            <MdKeyboardDoubleArrowLeft className='text-2xl' />
          )}
        </div>
        <Link
          to={'/'}
          onClick={() => dispatch(resetSearch())}
          className='flex items-center gap-3'
        >
          <img
            src='/assets/images/logo.png'
            className='w-[57px] h-[58px]'
            alt='logo'
          />
          <span className='font-medium text-2xl text-nowrap leading-[32px]'>
            Asset Watch
          </span>
        </Link>
        <span className='h-[52px] xl:hidden block ml-1 bg-[#FFFFFF33] w-[1px] ' />
        {sidebarItems?.map((item) => (
          <Link
            onClick={() => dispatch(resetSearch())}
            to={item?.path}
            key={item?.name}
          >
            <div
              className={`text-white text-nowrap cursor-pointer xl:hidden block ${
                item?.path === location?.pathname ? 'opacity-100' : 'opacity-50'
              }`}
            >
              {item?.name}
            </div>
          </Link>
        ))}
      </div>
      <div className='flex items-center gap-5 sm:pt-3 sm:w-full'>
        <div className='relative block w-full ml-2'>
          <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
            <GoSearch />
          </span>
          <input
            placeholder='Search a VIN or customer name...'
            value={search}
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
            className='text-sm border border-[#FFFFFF33] bg-transparent h-[48px] max-w-[360px] w-full rounded-lg pl-8 pr-3 focus:outline-none placeholder-[#FFFFFF99]'
          />
        </div>

        <div className='border xl:hidden block rounded-full border-[#FFFFFF33] cursor-pointer'>
          <IoIosSettings className='text-2xl m-4' />
        </div>

        <Popup
          trigger={
            <div className='border xl:hidden block rounded-full border-[#FFFFFF33] cursor-pointer'>
              <PiLifebuoy className='text-2xl m-4' />
            </div>
          }
          position={['bottom']}
          closeOnDocumentClick
          contentStyle={{ width: '350px' }}
        >
          <div className='p-3'>
            {terminalProductsList?.map((item) => (
              <div className='mb-5 font-poppinsRegular' key={item.title}>
                <h2 className='font-bold mb-3'>{item?.title}</h2>
                <div className='flex flex-wrap gap-2'>
                  {item?.products?.length > 0
                    ? item?.products?.map((product) => (
                        <Link
                          onClick={() => dispatch(resetSearch())}
                          to={product?.link}
                          key={product.title}
                          className={`border bg-[#FAFAFA] rounded-xl p-3 text-sm ${
                            product?.link
                              ? 'hover:bg-peach hover:text-orange hover:border-orange cursor-pointer'
                              : 'bg-[#e7e6e6] border-[#c6c6c6] cursor-not-allowed'
                          }`}
                        >
                          {product?.title}
                        </Link>
                      ))
                    : ''}
                </div>
              </div>
            ))}
          </div>
        </Popup>

        <span className='h-[52px] xl:hidden block bg-[#FFFFFF33] shrink-0 w-[1px]' />
        <div className='group cursor-pointer inline-flex gap-3 items-center rounded-xl text-base font-medium hover:text-white'>
          <img
            src='https://pagedone.io/asset/uploads/1705471668.png'
            className='size-12 object-cover rounded-full'
            alt='profile'
            draggable={false}
          />
          <h3 className='font-poppinsSemibold text-base'>John Doe</h3>
          <IoIosArrowDown className='text-xl' />
        </div>
      </div>
    </div>
  );
}
