import { FaMapPin } from 'react-icons/fa';
import { SiMercedes } from 'react-icons/si';
import { GrOverview } from 'react-icons/gr';
import { MdEventNote } from 'react-icons/md';
import { FaChartLine } from 'react-icons/fa';
import { MdDataUsage } from 'react-icons/md';
import { IoMdSettings } from 'react-icons/io';
import { TiHomeOutline } from 'react-icons/ti';
import { GrUserManager } from 'react-icons/gr';
import { IoMoonOutline } from 'react-icons/io5';
import { TbReportMedical } from 'react-icons/tb';
import { MdOutlineGroups2 } from 'react-icons/md';
import { MdOutlineTravelExplore } from 'react-icons/md';

import { ReactComponent as ClustersIcon } from '../assets/svg/grid-search.svg';
import { ReactComponent as OdometerIcon } from '../assets/svg/tachometer-fast.svg';

export const sidebarItems = [
  {
    name: 'RepoPanel',
    path: '/repo-panel',
    icon: <TiHomeOutline />,
    match: '/repo-panel',
  },
  {
    name: 'FinDash',
    path: '/fin-dash',
    icon: <FaMapPin />,
    match: '/fin-dash',
  },
  {
    name: 'Lease Track',
    path: '/leas-dash',
    icon: <SiMercedes />,
    match: '/leas-dash',
  },
];

// repoPanel tabs
export const clusterTabs = [
  {
    id: 0,
    key: 'cluster',
    title: 'Clusters',
    icon: ClustersIcon,
  },
  {
    id: 1,
    key: 'nightlypark',
    icon: IoMoonOutline,
    title: 'Nightly Parking',
  },
  {
    id: 2,
    key: 'odometer',
    title: 'Odometer',
    icon: OdometerIcon,
  },
  {
    id: 4,
    key: 'reports',
    title: 'Reports',
    icon: FaChartLine,
  },
];

// finDash tabs
export const finDashTabs = [
  {
    id: 0,
    key: 'overview',
    icon: GrOverview,
    title: 'Overview',
  },
  {
    id: 1,
    icon: MdDataUsage,
    title: 'Usage',
  },
  {
    id: 2,
    title: 'Demography',
    icon: MdOutlineGroups2,
  },
];

// report tabs
export const reportsTabs = [
  {
    id: 0,
    title: 'Connectivity',
    icon: ClustersIcon,
  },
  {
    id: 1,
    icon: IoMoonOutline,
    title: 'DPD Buckets',
  },
  {
    id: 2,
    icon: IoMoonOutline,
    title: 'Make/Models',
  },
  {
    id: 3,
    title: 'Alerts',
    icon: OdometerIcon,
  },
  {
    id: 4,
    title: 'Odometer',
    icon: OdometerIcon,
  },
];

// search tabs
export const searchTabData = [
  {
    id: 0,
    title: 'Status',
    icon: MdDataUsage,
  },
  {
    id: 1,
    title: 'Trips',
    icon: MdOutlineTravelExplore,
  },
  {
    id: 2,
    title: 'Events',
    icon: MdEventNote,
  },
  {
    id: 3,
    title: 'Valuation (AI)',
    icon: MdEventNote,
  },
  {
    id: 4,
    title: 'Settings',
    icon: IoMdSettings,
  },
];

// reports insights
export const reportsInsights = [
  {
    id: 1,
    date: 'June 12,2024',
    title: '1100 out of 1300 85+ DPD vehicles Nissan Rogues are connected',
  },
  {
    id: 2,
    date: 'June 12,2024',
    title:
      '70 85+ DPD Nissan Rogues in TX can be tracked whereas 20 connected vehicles are not traceable',
  },
];

export const daysOfParking = [
  {
    title: '7 Days',
    id: 7,
  },
  {
    title: '6 Days',
    id: 6,
  },
  {
    title: '5 Days',
    id: 5,
  },
  {
    title: '4 Days',
    id: 4,
  },
  {
    title: '3 Days',
    id: 3,
  },
  {
    title: '2 Days',
    id: 2,
  },
  {
    title: '1 Day',
    id: 1,
  },
  {
    title: 'Unknown',
    id: 0,
  },
];

export const odometerFilter = [
  {
    title: '0 – 5K',
    id: '0-5k',
  },
  {
    title: '5K – 10K',
    id: '5k-10k',
  },
  {
    title: '10K - 25K',
    id: '10k-25k',
  },
  {
    title: '25K - 40K',
    id: '25k-40k',
  },
  {
    title: '40K - 60K',
    id: '40k-60k',
  },
  {
    title: '60K - 75K',
    id: '60k-75k',
  },
  {
    title: '75K+',
    id: '75k+',
  },
];

export const terminalProductsList = [
  {
    title: 'Finance & Insurance',
    products: [
      {
        title: 'RepoPanel',
        link: '/repo-panel',
      },
      {
        title: 'FinDash',
        link: 'fin-dash',
      },
      {
        title: 'FinDash',
        link: '/leas-dash',
      },
    ],
  },
  {
    title: 'Commercial & Rental Fleets',
    products: [
      {
        title: 'FleetView',
        link: '',
      },
      {
        title: 'FleetGuard',
        link: '',
      },
      {
        title: 'FleetOps',
        link: '',
      },
    ],
  },
  {
    title: 'Automakers',
    products: [
      {
        title: 'AutoRent',
        link: '',
      },
      {
        title: 'WarrantyPulse',
        link: '',
      },
    ],
  },
  {
    title: 'Dealerships',
    products: [
      {
        title: 'SalesNavigator',
        link: '',
      },
      {
        title: 'OrderTrac',
        link: '',
      },
      {
        title: 'AutoLot',
        link: '',
      },
    ],
  },
  {
    title: 'Others',
    products: [
      {
        title: 'ShipmentSync',
        link: '',
      },
      {
        title: 'AuditIQ',
        link: '',
      },
    ],
  },
];

export const defaultMapBounds = {
  north: 49.384358,
  south: 24.396308,
  west: -125.0,
  east: -66.93457,
};
