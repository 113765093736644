import React, { useEffect, useState } from 'react';

import { FaLock } from 'react-icons/fa';
import { GiCarWheel } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { MdOutlineSpeed } from 'react-icons/md';
import { FaCircleCheck } from 'react-icons/fa6';
import { FaLocationDot } from 'react-icons/fa6';
import { IoIosArrowBack } from 'react-icons/io';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { TbLayoutSidebarRightCollapse, TbLocationFilled } from 'react-icons/tb';

import { ClusteredCars } from '../ClusteredCars';

import {
  changeSelectedVehicleTracking,
  searchSelectedCar,
} from '../../redux/slices/profileSlice';
import { ReactComponent as FuelIcon } from '../../assets/svg/fuel.svg';
import { ReactComponent as CarIcon } from '../../assets/svg/car_top_view_icon.svg';
import { GoDotFill } from 'react-icons/go';

const Status = ({ drawer, setDrawer, searchResult }) => {
  const dispatch = useDispatch();

  const carDetails = searchResult?.selectedCar;

  const [zoomLevel, setZoomLevel] = useState(0);
  const [hoverClusterDetails, setHoverClusterDetails] = useState(null);

  useEffect(() => {
    if (searchResult?.selectedCar?.id) {
    const params = {
      carid: searchResult?.selectedCar?.id,
    };
    const queryParams = new URLSearchParams(params).toString();
    const socket = new WebSocket(
      `wss://demo.assetwatch.info/ws/updates?${queryParams}`, [`AssetWatch`]
    );

    // Event listener for receiving messages
    socket.onmessage = (event) => {
      const data = JSON.parse(event?.data);
      dispatch(changeSelectedVehicleTracking(data));
    };

    return () => {
      socket.close();
    };
    }
  }, [searchResult?.selectedCar]);

  const handleZoomChange = ({ detail }) => {
    setZoomLevel(detail?.zoom);
    if (zoomLevel >= detail?.zoom) {
      setHoverClusterDetails(null);
    }
  };
  return (
    <div className='flex-1 flex md:flex-col mb-8 gap-3 overflow-hidden pt-3'>
      {drawer?.cars || drawer?.carsAnimation ? (
        <div
          className={`xl:fixed xl:ml-0 ml-4 left-0 top-0 z-[2] xl:shadow-lg bg-white rounded-[20px] border-r xl:border-r-0 xl:border-b xl:overflow-auto w-full h-full max-w-[400px] overflow-auto scrollbar-none relative ${
            drawer?.cars ? 'slider-in' : 'slider-out'
          }`}
          onAnimationEnd={() => {
            if (!drawer?.cars) {
              setDrawer({ ...drawer, carsAnimation: false });
            }
          }}
        >
          <div className='flex gap-3 flex-col py-4 px-5'>
            <div className='flex justify-end'>
              <button
                className=''
                onClick={() =>
                  setDrawer({ ...drawer, cars: false, carsAnimation: true })
                }
              >
                <IoIosArrowBack color='#939393' fontSize={24} />
              </button>
            </div>

            {carDetails ? (
              <div>
                <div className='p-5'>
                  <div className='flex gap-7 mb-7'>
                    <FaLocationDot className='text-[32px] text-orange	' />
                    <div className='flex flex-col'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Location{' '}
                      </span>
                      <span> {carDetails?.details?.location?.address} </span>
                      <span className='text-[#12A67A]'>
                        {' '}
                        Last Updated :{' '}
                        {carDetails?.details?.location?.lastUpdatedAt}{' '}
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-7 mb-7'>
                    <MdOutlineSpeed className='text-[32px] text-orange	' />
                    <div className='flex flex-col mb-2'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Odometer{' '}
                      </span>
                      <span>{carDetails?.details?.odometer || '-'}</span>
                      {!carDetails?.details?.odometer && (
                        <span className='text-[#AE2E38]'>
                          Vehicle Mileage status data is currently not
                          available.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='flex gap-7 mb-7'>
                    <FuelIcon className='fill-orange h-[32px] w-[32px]' />
                    <div className='flex flex-col mb-2'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Distance of Empty{' '}
                      </span>
                      <span>
                        {carDetails?.details?.fuel ||
                          'Vehicle fuel status data is currently not available.'}
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-7 mb-7'>
                    <GiCarWheel className='text-[32px] text-orange	' />
                    <div className='flex flex-col mb-2'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Tire pressure (bar){' '}
                      </span>
                      <div className='relative h-28 w-30'>
                        <CarIcon className='h-full w-full fill-gray-300' />
                        <span className='absolute top-0 left-0 text-xs p-1'>
                          {carDetails?.details?.tirePressure?.frontLeft?.toFixed(
                            2
                          )}
                        </span>
                        <span className='absolute top-0 right-0 text-xs p-1'>
                          {carDetails?.details?.tirePressure?.frontRight?.toFixed(
                            2
                          )}
                        </span>
                        <span className='absolute bottom-0 left-0 text-xs p-1'>
                          {carDetails?.details?.tirePressure?.rearLeft?.toFixed(
                            2
                          )}
                        </span>
                        <span className='absolute bottom-0 right-0 text-xs p-1'>
                          {carDetails?.details?.tirePressure?.rearRight?.toFixed(
                            2
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-7 mb-7'>
                    <IoWarning className='text-[32px] text-orange' />
                    <div className='flex flex-col mb-2'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Health Status{' '}
                      </span>
                      <span className='flex gap-3 items-center'>
                        All :
                        {carDetails?.details?.healthStatus?.status ? (
                          <FaCircleCheck fill='green' fontSize={18} />
                        ) : (
                          <IoCloseCircleSharp fill='red' fontSize={18} />
                        )}
                      </span>
                      <span>
                        {' '}
                        Last Updated :{' '}
                        {carDetails?.details?.healthStatus?.lastUpdatedAt}{' '}
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-7 mb-7'>
                    <FaLock className='text-[32px] text-orange' />
                    <div className='flex flex-col mb-2'>
                      <span className='font-bold mb-2 text-[16px]'>
                        {' '}
                        Health Status{' '}
                      </span>
                      <span>
                        {carDetails?.details?.lockStatus ||
                          'Vehicle lock status data is currently not availabe.'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : searchResult?.cars?.length ? (
              searchResult?.cars.map((car) => (
                <div
                  className='flex cursor-pointer hover:bg-[#E3E6EA] rounded-2xl'
                  onClick={() => dispatch(searchSelectedCar(car))}
                  key={car?.id}
                >
                  <img
                    src={car?.companyLogo}
                    alt='company logo'
                    className='h-[100px] w-[100px]'
                  />
                  <div className='flex flex-col border w-full rounded-2xl gap-3 p-3 justify-center'>
                    <span>{car?.carModel}</span>
                    <span>{car?.carNumber}</span>
                  </div>
                </div>
              ))
            ) : (
                  <div className='text-center'>Car not found!</div>
            )}
          </div>{' '}
        </div>
      ) : (
        <button
          onClick={() => setDrawer({ ...drawer, cars: true })}
          className='h-[52px] w-[56px] bg-white rounded-r-full shadow-lg'
        >
          <TbLayoutSidebarRightCollapse
            size={28}
            color='#939393'
            className='h-full m-auto'
          />
        </button>
      )}

      <div className='flex-1 flex flex-col gap-3 overflow-hidden md:p-4 pr-4 min-h-[200px]'>
        <div className='tab-content flex-1 font-poppinsRegular overflow-auto bg-white rounded-[20px]'>
          <div className='grid grid-cols-1 h-full'>
            {searchResult?.selectedCar ? (
              <div className='col-span-3 p-3 overflow-auto flex flex-col'>
                <div className='relative min-h-[500px]'>
                  {searchResult?.selectedVehicleTrack && <div className='absolute z-[1] bottom-7 font-poppins p-3 left-3 bg-white'>
                    <div className='flex gap-2 mb-2'>
                      <GoDotFill className='text-red-600' />
                      <span className='text-xs'>Stopped car</span>
                    </div>
                    <div className='border-b mb-2' />
                    <div className='flex gap-2'>
                      <TbLocationFilled className='text-green-700' />
                      <span className='text-xs'>Running car</span>
                    </div>
                  </div>}
                  {searchResult?.selectedVehicleTrack && (
                    <APIProvider
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    >
                      <Map
                        options={{
                          restriction: {
                            latLngBounds: searchResult?.selectedCar?.mapBounds,
                          },
                        }}
                        zoom={zoomLevel}
                        disableDefaultUI
                        zoomControl={true}
                        gestureHandling={'greedy'}
                        className='map h-full w-full'
                        onZoomChanged={handleZoomChange}
                        mapId={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                        defaultCenter={{
                          ...searchResult?.selectedVehicleTrack?.position,
                        }}
                      >
                        <ClusteredCars
                          isSearchMap={true}
                          onHoverDetails={hoverClusterDetails}
                          setOnHoverDetails={setHoverClusterDetails}
                          stateWiseCars={searchResult?.selectedVehicleTrack}
                        />
                      </Map>
                    </APIProvider>
                  )}
                </div>
                <div className='p-5'>
                  <h1 className='text-2xl mb-5'>
                    {searchResult?.selectedCar?.carModel}
                  </h1>

                  {searchResult?.selectedCar?.description && (
                    <p className='mt-5 font-poppinsRegular'>
                      {searchResult?.selectedCar?.description}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className='m-auto sm:p-3 md:mt-14'>
                Please select the car you want to see details for.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
