import React from 'react';
import { CiExport } from 'react-icons/ci';
import PopoverHeadless from './PopoverHeadless';

const TabsAndSearch = ({
  loading,
  filters,
  classes,
  isSearch,
  setFilters,
  clusterTabs,
  rootClasses,
  hiddenExport,
  hiddenTabIcons,
}) => {
  const activeTabId = isSearch ? filters : filters?.activeClusterTab?.id;
  return (
    <div
      className={`flex bg-white p-2 rounded-[20px] mx-4 mt-3 md:flex-col gap-3 justify-between ${
        hiddenTabIcons ? 'pb-0 overflow-hidden' : ''
      } ${rootClasses}`}
    >
      <ul
        className={`tab-list flex overflow-x-auto scrollbar-none justify-center xs:justify-start ${classes}`}
      >
        {clusterTabs.map((tab) => (
          <li
            key={tab.id}
            className={`flex-shrink-0 ${
              activeTabId === tab?.id && hiddenTabIcons
                ? 'border-b-2 border-orange'
                : ''
            }`}
          >
            <button
              className={`text-nowrap flex h-full gap-2 rounded-xl items-center px-3 py-2 tracking-wide font-poppinsRegular ${loading && "cursor-not-allowed"} ${
                hiddenTabIcons ? 'pb-4' : 'min-h-14'
              } ${
                activeTabId === tab?.id
                  ? `opacity-100  ${
                      !hiddenTabIcons ? 'bg-peach' : 'text-orange'
                    }`
                  : 'opacity-50'
              }`}
              onClick={() =>
                setFilters(
                  isSearch ? tab?.id : { ...filters, activeClusterTab: tab }
                )
              }
              disabled={loading}
            >
              {!hiddenTabIcons && (
                <span
                  className={`text-[20px] p-[10px] rounded-full ${
                    activeTabId === tab?.id ? 'bg-orange' : 'bg-[#0000000A]'
                  }`}
                >
                  <tab.icon
                    className={`${
                      activeTabId === tab?.id
                        ? 'text-white fill-white'
                        : 'text-black fill-black'
                    }`}
                  />
                </span>
              )}
              {tab?.title}
            </button>
          </li>
        ))}
      </ul>

      {!isSearch && !hiddenExport && (
        <div className='md:w-full my-auto'>
          <PopoverHeadless
            buttonToggle={
              <div className='text-[20px] md:w-full w-fit justify-center text-white bg-orange text-nowrap flex gap-3 h-[48px] items-center rounded-lg px-[19px]'>
                <CiExport />{' '}
                <span className='text-base font-semibold leading-6'>
                  Export
                </span>
              </div>
            }
          >
            <div className='px-3 py-2 hover:bg-gray-100 cursor-pointer text-base text-gray-600'>
              Print
            </div>
            <div className='px-3 py-2 hover:bg-gray-100 cursor-pointer text-base text-gray-600'>
              Report
            </div>
          </PopoverHeadless>
        </div>
      )}
    </div>
  );
};

export default TabsAndSearch;
